<template>
	<a-range-picker
		class="mydate-picker-range"
		:value="internalValue"
		:default-value="internalDefaultValue"
		:disabled="disabled"
		:disabled-date="disabledDate"
		:disabled-time="disabledTime"
		separator="-"
		input-read-only
		:ranges="presetRanges"
		:format="format"
		:allow-clear="allowClear"
		@change="onChange"/>
</template>

<script>
/* eslint-disable vue/require-default-prop */
import {DatePicker} from "ant-design-vue"
import _get from "lodash/get"

function convertToDisplay(component,value) {
	return value ? component.$dayjs(value) : null
}
function convertToValue(component,format,value) {
	return value ? component.$dayjs(value,format).format('YYYY-MM-DD') : null
}

export default {
	components : {
		"a-range-picker" : DatePicker.RangePicker
	} ,
	inheritAttrs : false,
	model : {
		prop : 'value',
		event : 'change'
	} ,
	props : {
		value : {
			type : null,
			default : undefined
		} ,
		defaultValue : {
			type : null,
			default : undefined
		} ,
		disabledDate : {
			type : Function,
			default : undefined
		} ,
		disabledTime : {
			type : Function ,
			default : undefined,
		} ,
		disabled : {
			type : Boolean,
			default : false
		} ,
		allowClear : {
			type :Boolean,
			default : true,
		} ,
		format : {
			type : String,
			default : 'D MMMM YYYY'
		} ,
		hidePreset : {
			type : Boolean,
			default : false,
		}
	} ,
	computed : {
		internalDefaultValue() {
			const start = _get(this.defaultValue,'start')
			const end = _get(this.defaultValue,'end')
			if (start && end) {
				return [convertToDisplay(this,start),convertToDisplay(this,end)]
			} else {
				return []
			}
		} ,
		internalValue() {
			const start = _get(this.value,'start')
			const end = _get(this.value,'end')
			return [convertToDisplay(this,start),convertToDisplay(this,end)]
		} ,
		presetRanges() {
			if (!this.hidePreset) {
				const ranges = {};
				ranges[this.$t('common.day.this')] = [this.$dayjs(),this.$dayjs()]
				ranges[this.$t('common.week.this')] = [this.$dayjs().startOf('week'), this.$dayjs().endOf('week')]
				ranges[this.$t('common.month.this')] = [this.$dayjs().startOf('month'), this.$dayjs().endOf('month')]
				return ranges;
			} else {
				return undefined
			}
		}
	} ,
	methods : {
		onChange(newVal) {
			const start = _get(newVal,0)
			const end = _get(newVal,1)
			this.$emit('change',{
				start : convertToValue(this,this.format,start) ,
				end : convertToValue(this,this.format,end) ,
			})
		}
	}
}
</script>
<style lang="less">
.mydate-picker-range {
	width : 300px;
	min-width : 250px;
	.ant-calendar-range-picker-input {
		width : 125px;
	}
	.mobile & {
		width : 100%;
		max-width : 275px;

		.ant-calendar-range-picker-input {
			width : 110px;
			font-size : 0.90em;
		}
		.ant-calendar-picker-clear {
			right : 6px;
		}
	}
}
</style>
