<i18n locale="th" lang="yaml" >
page.title : "รายงานการใช้อะไหล่"
page.description : "สรุปรายงานการใช้อะไหล่ ในการซ่อมของศูนย์บริการ โดยจะแสดงเฉพาะการเปลี่ยนอะไหล่ของงานซ่อมที่เสร็จสมบูรณ์แล้วเท่านั้น (เลือกช่วงเวลาได้มากสุดระยะ 90 วัน เท่านั้น)"

part_usage.field.change_date : "วันที่"
part_usage.field.company : "ศูนย์"
part_usage.field.company.placeholder : "เลือกศูนย์บริการ"
part_usage.submit.label : "เรียกดู"

part_usage.table.part.sku : "SKU"
part_usage.table.part : "อะไหล่"
part_usage.table.normal : "การเปลี่ยนอะไหล่"
part_usage.table.normal.num_add : "ติดตั้ง"
part_usage.table.normal.num_remove : "เอาออก"
part_usage.table.normal.num_claim : "เคลม"
part_usage.table.recall : "การเรียกคืนอะไหล่"
part_usage.table.recall.num_add : "ติดตั้ง"
part_usage.table.recall.num_remove : "เอาออก"
part_usage.table.total_claim : "เบิกเคลมทั้งหมด"
part_usage.num_part.display : "{count}"
part_usage.num_part.display.zero : "-"

</i18n>

<template>
	<div class="page page-padding">
		<my-page-header :title="$t('page.title')" :description="$t('page.description')" />
		<div class="myform-action-pane">
			<a-form layout="inline">
				<a-form-item :label="$t('part_usage.field.change_date')">
					<MyRangeDatePicker
						v-model="filter.return_dates" :allow-clear="false"/>
				</a-form-item>
				<a-form-item :label="$t('part_usage.field.company')">
					<CompanyMultipleSelect
						allow-clear
						:placeholder="$t('part_usage.field.company.placeholder')"
						@change="handleCompanyChange"/>
				</a-form-item>
				<a-form-item>
					<a-button type="primary" :disabled="!canSubmit" @click="debounceSearchChange ">
						{{$t('part_usage.submit.label')}}
					</a-button>
    		</a-form-item>
			</a-form>
		</div>
		<a-card :bordered="false">
			<a-table
				:loading="loading"
				:columns="partColumns"
				:data-source="usageParts"
				bordered
				:pagination="false"
				size="small"
				class="mytable mytable-scroll part-usage-table">
				<span slot="partNum" slot-scope="num">
					{{$tc_my('part_usage.num_part.display',num)}}
				</span>
				<div slot="partSKU" slot-scope="record">
					<span v-if="$notEmpty(record.part)">
						{{record.part.sku}}
					</span>
					<span v-else>-</span>
				</div>
				<div slot="partDetail" slot-scope="record">
					<MyPopover
						v-if="$notEmpty(record.part)"
						class="part-name part-name-valid"
						overlay-class-name="mycard-popover" :destroy-tooltip-on-hide="true">
						<template slot="content">
							<PartLiteCard :part="record.part" size="small"  link-target="_blank" />
						</template>
						{{record.part.nameTh}}
					</MyPopover>
					<span v-else>
						{{record.part_name | emptyCheck}}
					</span>
				</div>
			</a-table>
		</a-card>
	</div>
</template>

<script>
import LoginInfoMixin from "@mixins/LoginInfoMixin.vue"
import MyRangeDatePicker from "@components/input/MyRangeDatePicker.vue"
import CompanyMultipleSelect from "@components/company/CompanyMultipleSelect.vue"
import MyPopover from "@components/common/MyPopover.vue"
import PartLiteCard from "@components/part/PartLiteCard.vue"
import {Table} from "ant-design-vue"
import axios from "axios"
import ApiError from "@utils/errors/ApiError"
import {mapGetters} from "vuex"
import _isEqual from "lodash/isEqual"
import _isEmpty from "lodash/isEmpty"
import debounce from "lodash/debounce"
export default {
	components : {
		"a-table" : Table,
		CompanyMultipleSelect,MyRangeDatePicker , MyPopover,PartLiteCard
	} ,
	mixins : [LoginInfoMixin] ,
	page() {
		return {
			title : this.$t('page.title')
		}
	} ,
	data() {
		return {
			loading : false,
			usageParts : [],
			filter : {
				company_id : [],
				return_dates : {}
			},
		}
	} ,
	computed : {
		...mapGetters('drone',['getPartById']) ,
		canSubmit() {
			if (this.$notEmpty(this.filter.return_dates.start)  && this.$notEmpty(this.filter.return_dates.end)) {
				const end = this.$dayjs(this.filter.return_dates.end)
				return end.diff(this.filter.return_dates.start,'day') <= 90
			}
			return false
		} ,
		partColumns() {
			return [
				{
					title : this.$t('part_usage.table.part.sku') ,
					key : 'part_sku' ,
					scopedSlots: { customRender: 'partSKU' },
					class : 'col-sku' ,
				} ,
				{
					title : this.$t('part_usage.table.part') ,
					key : 'part' ,
					scopedSlots: { customRender: 'partDetail' },
				} ,
				{
					title : this.$t('part_usage.table.normal') ,
					children : [
						{
							title : this.$t('part_usage.table.normal.num_add') ,
							dataIndex : 'usage_add',
							key : 'usage_add' ,
							scopedSlots: { customRender: 'partNum' },
							class : 'col-part-num' ,
						} ,
						{
							title : this.$t('part_usage.table.normal.num_remove') ,
							dataIndex : 'usage_remove',
							key : 'usage_remove' ,
							scopedSlots: { customRender: 'partNum' },
							class : 'col-part-num' ,
						} ,
						{
							title : this.$t('part_usage.table.normal.num_claim') ,
							dataIndex : 'usage_claim',
							key : 'usage_claim',
							scopedSlots: { customRender: 'partNum' },
							class : 'col-part-num' ,
						} ,
					]
				} ,
				{
					title : this.$t('part_usage.table.recall') ,
					children : [
						{
							title : this.$t('part_usage.table.recall.num_add') ,
							dataIndex : 'recall_add',
							key : 'recall_add' ,
							scopedSlots: { customRender: 'partNum' },
							class : 'col-part-num' ,
						} ,
						{
							title : this.$t('part_usage.table.recall.num_remove') ,
							dataIndex : 'recall_remove',
							key : 'recall_remove',
							scopedSlots: { customRender: 'partNum' },
							class : 'col-part-num' ,
						} ,
					]
				} ,
				{
					title : this.$t('part_usage.table.total_claim') ,
					dataIndex : 'total_claim' ,
					key : 'total_claim' ,
					scopedSlots: { customRender: 'partNum' },
					class : 'col-part-num' ,
				}
			]
		}
	} ,
	mounted() {
		this.filter.return_dates = {
			start : this.$dayjs().subtract(1, 'month').format('YYYY-MM-DD') ,
			end : this.$dayjs().format('YYYY-MM-DD')
		}
		this.fetchData()
	} ,
	created() {
		this.debounceSearchChange = debounce(this.handleSearch,300)
	} ,
	methods : {
		handleSearch() {
			this.fetchData()
		} ,
		fetchData() {
			this.loading = true
			const filter = {...this.filter}
			axios.get("/api/parts/search-usage",{params : {filter}}).then((response) => {
				const parts = response.data.data.parts
				parts.forEach((p) => {
					if (!_isEmpty(p.part_id)) {
						p.part = this.getPartById(p.part_id)
					}
				})
				this.usageParts = [...parts]
			}).catch((error) => {
				this.$message.error(ApiError.getDisplayErrorMessage(this,error))
			}).finally(()=>{
				this.loading = false
			})
		} ,
		handleCompanyChange(companyId) {
			if (!_isEqual(companyId, this.filter.company_id)) {
				this.filter.company_id = companyId
			}
		} ,
	}

}
</script>

<style lang="less" scoped>
.part-name-valid {
	color : @info-color;
}
.part-usage-table::v-deep {
	min-width : 700px;
	th {
		text-align: center;
	}
	td.col-part-num {
		text-align : right;
	}
	.col-part-num {
		width : 80px;
	}
	.col-sku {
		width : 150px;
	}
	.mobile & .ant-table ,
	.tablet & .ant-table {
		font-size : 0.9em;
		.col-sku {
			width : 125px;
		}
		.col-part-num {
			width : 60px;
		}
	}
}

</style>
